import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fontSize } from '../../../../components/Shared/Style/typography';
import { colors } from '../../../../components/Shared/Style/colors';
import useGetBookingResourceById from '../../hooks/useGetBookingResourceById';
import Dialog from '../../../../components/Dialog/Dialog';
import ReactSelect from '../../../../components/Dropdown/ReactSelect';
import useGetUsersFromProject from '../../../../lib/hooks/useGetUsersFromProject';
import { L } from '../../../../lib/i18n';
import H2 from '../../../../components/typography/H2';
import TimeSlotsProvider from '../../context/useTimeSlots';
import TimeSlotGrid from '../../BookingSlots/TimeSlotGrid';
import TimeSlotNav from '../../BookingSlots/TimeSlotNav';

const Layout = styled.div({
    display: 'grid',
    width: '100%',
    backgroundColor: colors.white,
    maxHeight: '100vh',
    padding: '1rem 0px 1rem 0px',
    gridTemplateRows: 'auto 1fr',
    gap: '16px',
    gridTemplateAreas: `
        "nav"
        "timeslot"
    `,
});

const PagePadding = styled.div({
    padding: '16px',
    width: '100%',
    overflowY: 'scroll',
});

const Select = styled(ReactSelect)({
    '#react-select-2-input': {
        caretColor: 'black',
        position: 'absolute',
        left: 10,
        top: 6,
    },
    marginBottom: '1rem',
});

const DropdownLabel = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: fontSize.milli,
});

type Props = {
    bookingId: string;
};

const BookingsSlotForm = ({ bookingId }: Props) => {
    const { data: bookingResource } = useGetBookingResourceById(+bookingId);
    const history = useHistory();
    const [selectedUser, setSelectedUser] = useState<number>();

    const { data: users } = useGetUsersFromProject();

    const options =
        users?.map((user) => ({
            value: [user.id, user.name, user.email, user.property_name],
            label: (
                <DropdownLabel>
                    <span>{user.name || user.email} </span>
                    <span> {user.property_name} </span>
                </DropdownLabel>
            ),
        })) || [];
    if (!bookingResource) return <div>Kunde inte hitta kalendern</div>;
    return (
        <>
            <Dialog
                open={true}
                content={{
                    style: {
                        width: '400px',
                        padding: '2rem',
                        backgroundColor: colors.white,
                    },
                }}
                onOpenChange={(open) => {
                    if (!open) history.push(`/booking/${bookingId}`);
                }}
            >
                <H2>
                    {L('new_booking')} {bookingResource.name}
                </H2>
                <Select
                    isClearable
                    value={options.find((option) => selectedUser && option.value === selectedUser[0])}
                    onChange={(
                        option:
                            | { value: { id: string; user: string; email: string; property_name: string } }
                            | undefined
                    ) => {
                        setSelectedUser(option?.value[0]);
                    }}
                    options={options}
                    placeholder={L('book_for_user')}
                />
                <TimeSlotsProvider>
                    <Layout>
                        <TimeSlotNav />
                        <PagePadding>
                            <TimeSlotGrid
                                selectedUser={users?.find((user) => selectedUser && +user.id === +selectedUser)}
                                bookingId={+bookingId}
                            />
                        </PagePadding>
                    </Layout>
                </TimeSlotsProvider>
            </Dialog>
        </>
    );
};

export default BookingsSlotForm;
